import React, { useEffect, useState, useMemo } from 'react';

import ReactRepository from '../ReactRepository/ReactRepository.react';

import store from '~store/index';

import { IRoute, IRouter, Organization, Repository } from '~utils/global.types.react';
import { SET_ORGANIZATION, SET_ORG_REPOSITORIES, SET_REPOSITORY } from '~store/action.types';

import { sendRequest } from '~utils/request';
import logger from '~utils/logger';

type PublicRepositoryProps = {
  route: IRoute;
  router: () => IRouter;
};

const PublicRepository = ({ route, router }: PublicRepositoryProps) => {
  const ghOrg = route?.params?.ghOrg;
  const ghRepo = route?.params?.ghRepo;
  const targetBranch = route?.params?.targetBranch;

  const [org, setOrg] = useState<Organization | null>(null);
  const [repos, setRepos] = useState<Repository[]>([]);
  const [repo, setRepo] = useState<Repository | null>(null);

  useEffect(() => {
    const onLoad = async () => {
      const orgName = await getOrgName();

      if (orgName) {
        setOrg(getOrgDetail(orgName));
        const retrievedRepos = await getRepos(orgName);
        setRepos(retrievedRepos);
        setRepo(getRepo(retrievedRepos));
      }
    };

    onLoad();
  }, []);

  const getOrgName = async () => {
    let response;

    if (!ghOrg || !ghRepo) return null;
    try {
      response = await sendRequest('GET', `/v0/gh/${ghOrg}/${ghRepo}`, {}, null);
    } catch (e) {
      logger.logError('public_repository_error_retrieving_organization_name', e);
    }

    return response.Organization;
  };

  const getOrgDetail = (orgName: string) => {
    if (!orgName) return null;

    const orgDetails = {
      CreatedBy: '',
      CreatedOn: '',
      DeletedAt: null,
      GithubEnterpriseBaseURL: '',
      HasReceivedDataFromAgent: false,
      Name: orgName,
      SSOStrict: false,
      Tier: 3,
      TrialExpiresAt: null,
    };
    store.commit(SET_ORGANIZATION, orgDetails);

    return orgDetails;
  };

  const getRepos = async (orgName: string) => {
    if (!orgName) return null;
    let response;

    try {
      const searchParams = new URLSearchParams(`repository=${ghOrg}/${ghRepo}`);
      response = await sendRequest(
        'GET',
        `/v0/organizations/${orgName}/ci/repositories?${searchParams}`,
        {},
        null,
      );
    } catch (e) {
      logger.logError('public_repository_error_retrieving_repositories', e);
    }

    store.commit(SET_ORG_REPOSITORIES, response);
    return response;
  };

  const getRepo = (retrievedRepos: Repository[]) => {
    if (!ghOrg || !ghRepo || !retrievedRepos.length) return null;

    const foundRepo = retrievedRepos.find((repo) => repo.Name === `${ghOrg}/${ghRepo}`) || null;
    store.commit(SET_REPOSITORY, foundRepo);
    return foundRepo;
  };

  const showPage = useMemo(() => {
    return ghOrg && ghRepo && org && repos && repo;
  }, [ghOrg, ghRepo, org, repos, repo]);

  return (
    <>
      {showPage ? (
        <ReactRepository route={route} router={router} targetBranch={targetBranch} />
      ) : (
        <></>
      )}
    </>
  );
};

export default PublicRepository;
